
.cardNumber {
    :global(.ant-form-item){
        width: 100%;
    }

    :global(.ant-form-item-control-input-content > span.pro-field){
        width: 100%;
    }
}
@primary-color: #ffc53d;