.layout {
  min-height: 100vh;
}

.logo {
  line-height: 50px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.site-layout .site-layout-background {
  background: #fff;
}

.content-layout-background {
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.header-right-container{
  display: flex;
  float: right;
  height: 64px;
  margin-left: auto;
  overflow: hidden;
  padding-right: 24px;
}

.header-right-action{
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all .3s;
}

.header-right-action :global(.ant-space-item){
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-right-action svg {
  font-size: 18px;
}

@primary-color: #ffc53d;