@import '~antd/es/style/themes/default.less';

.main {
  display: flex;
  width: 100%;
  height: 500px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: @menu-bg;
  .leftMenu {
    width: 224px;
    border-right: @border-width-base @border-style-base @border-color-split;
    :global {
      .ant-menu-inline {
        border: none;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        font-weight: bold;
      }
    }
  }
  .right {
    flex: 1;
    padding: 8px 40px;
    .title {
      margin-bottom: 12px;
      color: @heading-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  :global {
    .ant-list-split .ant-list-item:last-child {
      border-bottom: 1px solid @border-color-split;
    }
    .ant-list-item {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
}
:global {
  font.strong {
    color: @success-color;
  }
  font.medium {
    color: @warning-color;
  }
  font.weak {
    color: @error-color;
  }
}

@media screen and (max-width: @screen-md) {
  .main {
    flex-direction: column;
    .leftMenu {
      width: 100%;
      border: none;
    }
    .right {
      padding: 40px;
    }
  }
}

@primary-color: #ffc53d;