.panel {
  :global(.ant-dropdown) {
    width: 360px;
  }
}

.container {
  width: 280px;

  ul {
    padding: 16px;
  }
}

@primary-color: #ffc53d;