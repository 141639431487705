.form {
  position: absolute;
  width:5.6rem;
  height:4.3rem;
  background:rgba(255,255,255,1);
  border-radius:0.02rem;
  left:3rem;
  top: 0.5rem;
  padding: 0.34rem 0.74rem;
}

.form h2 {
  font-size: 0.28rem;
  margin-bottom: 0.1rem;
  font-weight:400;
}

.form .signup-message {
  font-size: 0.18rem;
  margin-bottom: 0.4rem;
}

.resetPassword {
  float: right;
}

.resetPassword span {
  margin-right: 0.09rem;
}
