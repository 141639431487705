.editTable {
    :global(.ant-table-placeholder){
        display: none;
    }
    :global(.ant-card-body){
        padding-bottom: 5px !important;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.groupTable {
    :global(.ant-card-body){
        padding-left: 0px;
        padding-right: 0px;
    }
}
@primary-color: #ffc53d;