
.layout > div:last-child{
    :global(.ant-select-arrow), :global(.ant-select-clear){
        display: none;
    }
    :global(.ant-select-selector){
        padding-left: 0px;
        padding-right: 0px;
        text-align: center;
        :global(.ant-select-selection-item){
            min-width: 100%;
            padding-right: 0px;
        }
    }
    :global(.ant-form-item .ant-select), :global(.ant-form-item .ant-cascader-picker){
        min-width: 25px !important;
    }
}

@media screen and (max-width: 1440px) {
    :global(.ant-select-dropdown){
        font-size: 12px !important;
        :global(.ant-select-item){
            font-size: 12px !important;
        }
    }
    .layout * {
        font-size: 12px;
    }
    .layout{
        :global(.ant-picker-content th), :global(.ant-picker-content td) {
            min-width: 16px !important;
            max-width: 24px !important
        }
    
        :global(.ant-picker-cell .ant-picker-cell-inner){
            min-width: 16px !important;
        }
    }

    
}


@primary-color: #ffc53d;